import {
  DesktopIcon,
  ExclamationTriangleIcon,
  GridIcon,
  MagicWandIcon,
} from '@radix-ui/react-icons';
import { Badge, Flex, Text } from '@radix-ui/themes';
import { MlbStatsHelper } from 'classes/helpers/mlb-stats.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import {
  AddAveragesIcon,
  AddPitchIcon,
  RowsAddIcon,
  SuperAdminIcon,
  VoidIcon,
} from 'components/common/custom-icon/shorthands';
import { PitchDataDialog } from 'components/common/dialogs/pitch-data';
import { ErrorBoundary } from 'components/common/error-boundary';
import { HINT_DELIMITER } from 'components/common/form/text-hint';
import { CommonTable } from 'components/common/table';
import { CommonTooltip } from 'components/common/tooltip';
import { GenerateVideoDialog } from 'components/sections/game-data/dialogs/generate-video';
import { ViewBroadcastDialog } from 'components/sections/game-data/dialogs/view-broadcast';
import { GameDataPitchesFilters } from 'components/sections/game-data/pitches/filters';
import { GameDataPlayerAvatar } from 'components/sections/game-data/players/avatar';
import { GAME_DATA_ID } from 'components/sections/game-data/tabs';
import { AimingContext } from 'contexts/aiming.context';
import { AuthContext } from 'contexts/auth.context';
import { CookiesContext } from 'contexts/cookies.context';
import { GameDataContext } from 'contexts/game-data.context';
import { GlobalContext } from 'contexts/global.context';
import { CheckedProvider } from 'contexts/layout/checked.context';
import { MachineContext } from 'contexts/machine.context';
import { PitchDesignContext } from 'contexts/pitch-lists/pitch-design.context';
import { SectionsContext } from 'contexts/sections.context';
import { VideosContext } from 'contexts/videos/videos.context';
import { GameDataTab } from 'enums/game-data.enums';
import { ResetPlateMode } from 'enums/machine.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { appearanceImgPath } from 'index';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { ITableAction } from 'interfaces/tables/action';
import { ITableCheckable } from 'interfaces/tables/checking';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { PlateHelper } from 'lib_ts/classes/plate.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { lookupPitchType, tooltipPitchType } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { useCallback, useContext, useMemo, useState } from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';

const COMPONENT_NAME = 'GameDataPitches';

const IDENTIFIER = TableIdentifier.PitchList;
const PAGE_SIZES = TABLES.PAGE_SIZES.LG;

const ENABLE_SKELETAL_DOWNLOAD = false;
const ENABLE_VIDEO_GENERATION = false;

const ERROR_ICON = <ExclamationTriangleIcon />;

const isObjectNonEmpty = (m: any, ignoreKeys?: string[]): boolean => {
  return (
    Object.keys(m)
      .filter((k) => !ignoreKeys || !ignoreKeys.includes(k))
      .filter((k) => {
        const v = m[k];

        if (v === undefined) {
          return false;
        }

        if (v === null) {
          return false;
        }

        if (typeof v === 'string') {
          // empty strings don't count
          return v.length > 0;
        }

        if (ArrayHelper.isArray(v)) {
          // empty arrays don't count
          return (v as any[]).length > 0;
        }

        return true;
      }).length > 0
  );
};

export const GameDataPitches = (props: {
  mode: GameDataTab;
  playerPk?: number;
  pitches: IMlbPitchExt[];
}) => {
  const globalCx = useContext(GlobalContext);
  const cookiesCx = useContext(CookiesContext);
  const authCx = useContext(AuthContext);
  const videosCx = useContext(VideosContext);

  const { loading, filterPitches, buildPitches, openAddDialog } =
    useContext(GameDataContext);

  const { app } = useContext(CookiesContext);
  const { current, restrictedGameStatus: restricted } = useContext(AuthContext);
  const { tryChangeSection } = useContext(SectionsContext);
  const { pitch: aimedPitch, setPitch: setAimedPitch } =
    useContext(AimingContext);
  const { machine, getSupportedPriority } = useContext(MachineContext);

  const { setReference } = useContext(PitchDesignContext);

  const [dialogBroadcast, setDialogBroadcast] = useState<number>();
  const [dialogData, setDialogData] = useState<number>();
  const [dialogVideo, setDialogVideo] = useState<number>();

  const [selectedGuid, setSelectedGuid] = useState<IMlbPitchExt>();

  const rowActions: ITableAction[] = useMemo(() => {
    const output: ITableAction[] = [
      {
        prefixIcon: <AddPitchIcon />,
        label: 'pd.add-to-pitch-list',
        invisibleFn: () => restricted,
        onClick: async (guid: IMlbPitchExt) => {
          openAddDialog({
            guids: [guid],
            pitcherPk: props.mode === 'pitchers' ? props.playerPk : undefined,
          });
        },
      },
      {
        prefixIcon: <GridIcon />,
        label: 'pl.view-pitch-data',
        onClick: async (guid: IMlbPitchExt) => {
          const built = (
            await buildPitches({
              priority: getSupportedPriority(app.pitch_upload_options.priority),
              pitches: [guid],
            })
          )[0];

          if (!built) {
            return;
          }

          setAimedPitch(built);
          setDialogData(Date.now());
        },
      },
      {
        prefixIcon: <MagicWandIcon />,
        label: 'pd.modify-in-pitch-design',
        invisibleFn: () => restricted,
        onClick: async (guid: IMlbPitchExt) => {
          const built = (
            await buildPitches({
              priority: getSupportedPriority(app.pitch_upload_options.priority),
              pitches: [guid],
            })
          )[0];

          if (!built) {
            return;
          }

          /** empty pitch _id => pitch design will only allow saving as a new pitch */
          setReference({
            ...built,
            // this will stop the PD context from replacing the pitch with the generic build
            _id: GAME_DATA_ID,
          });

          setTimeout(() => {
            tryChangeSection({
              trigger: `${COMPONENT_NAME} > context menu`,
              section: SectionName.Pitches,
              subsection: SubSectionName.Design,
            });
          }, 100);
        },
      },
      {
        prefixIcon: <DesktopIcon />,
        label: 'common.watch-broadcast',
        invisibleFn: () => restricted,
        onClick: async (guid: IMlbPitchExt) => {
          setSelectedGuid(guid);
          setDialogBroadcast(Date.now());
        },
      },
      {
        prefixIcon: <VoidIcon />,
        label: 'common.generate-video',
        color: RADIX.COLOR.WARNING,
        invisibleFn: () => !ENABLE_VIDEO_GENERATION || restricted,
        onClick: async (guid: IMlbPitchExt) => {
          setSelectedGuid(guid);
          setDialogVideo(Date.now());
        },
      },
      {
        prefixIcon: <VoidIcon />,
        label: 'common.skeletal-data',
        suffixIcon: <SuperAdminIcon />,
        color: RADIX.COLOR.WARNING,
        invisibleFn: () =>
          !ENABLE_SKELETAL_DOWNLOAD || current.role !== UserRole.admin,
        onClick: async (guid: IMlbPitchExt) => {
          const data =
            await MlbStatsService.getInstance().getGamePlaySkeletalData(
              guid.gamePk,
              guid.guid
            );

          if (!data) {
            return;
          }

          MiscHelper.saveAs(
            new Blob([JSON.stringify(data, null, 2)]),
            `game-${guid.gamePk}_play-${guid.guid}_skeletal.json`
          );
        },
      },
    ];

    return output;
  }, [machine, restricted, buildPitches, getSupportedPriority]);

  const columns: ITableColumn[] = useMemo(() => {
    const output: ITableColumn[] = [
      {
        label: 'common.actions',
        key: ACTIONS_KEY,
        actions: rowActions,
      },
      {
        label: '',
        key: '_issues',
        formatFn: (pitch: IMlbPitchExt) => {
          const errors = MlbStatsHelper.getErrors(pitch);
          if (errors.length > 0) {
            return (
              <CommonTooltip
                trigger={ERROR_ICON}
                content={
                  <p>
                    Invalid data detected:
                    <ul>
                      {errors.map((e, i) => (
                        <li key={`guid-${pitch.guid}-error-${i}`}>{e}</li>
                      ))}
                    </ul>
                  </p>
                }
              />
            );
          }

          return '';
        },
        sortRowsFn: (a: IMlbPitchExt, b: IMlbPitchExt, dir: number) => {
          const va = MlbStatsHelper.getErrors(a).length;
          const vb = MlbStatsHelper.getErrors(b).length;
          return dir * (va > vb ? 1 : -1);
        },
      },
      {
        invisible: props.mode === 'pitchers',
        label: 'common.pitcher',
        key: 'pitcher',
        formatFn: (m: IMlbPitchExt) => {
          return (
            <Flex gap="2" align="center">
              <GameDataPlayerAvatar
                size="1"
                playerPk={m.pitcherPk}
                name={m.pitcher}
              />
              <Text truncate>{m.pitcher}</Text>
            </Flex>
          );
        },
      },
      {
        invisible: props.mode === 'pitchers',
        label: '',
        key: 'pitchHand',
        formatFn: (m: IMlbPitchExt) => {
          const isLeft = m.pitchHand === 'L';
          return (
            <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
              {t(isLeft ? 'common.lhp' : 'common.rhp')}
            </Badge>
          );
        },
      },
      {
        invisible: props.mode === 'hitters',
        label: 'common.hitter',
        key: 'batter',
        formatFn: (m: IMlbPitchExt) => {
          return (
            <Flex gap="2" align="center">
              <GameDataPlayerAvatar
                size="1"
                playerPk={m.batterPk}
                name={m.batter}
              />
              <Text truncate>{m.batter}</Text>
            </Flex>
          );
        },
      },
      {
        invisible: props.mode === 'hitters',
        label: '',
        key: 'batSide',
        formatFn: (m: IMlbPitchExt) => {
          const isLeft = m.batSide === 'L';
          return (
            <Badge color={isLeft ? RADIX.COLOR.LEFT : RADIX.COLOR.RIGHT}>
              {t(isLeft ? 'hitters.lhh' : 'hitters.rhh')}
            </Badge>
          );
        },
      },
      {
        label: 'common.type',
        key: 'type',
        formatFn: (pitch: IMlbPitchExt) => {
          return lookupPitchType(pitch.type);
        },
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          const textA = pitchA.type ?? '';
          const textB = pitchB.type ?? '';
          return -dir * textA.localeCompare(textB);
        },
        tooltipFn: (p: IMlbPitchExt) => t(tooltipPitchType(p.type)).toString(),
      },
      {
        label: 'common.inning',
        key: '_inning',
        formatFn: (pitch: IMlbPitchExt) => pitch.count.inning,
        tooltipFn: (p: IMlbPitchExt) =>
          `At Bat: ${p.atBatNumber} ${HINT_DELIMITER} Pitch: ${p.pitchNumber}`,
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          return dir * (pitchA.timeCode < pitchB.timeCode ? -1 : 1);
        },
      },
      {
        label: 'common.outcome',
        key: 'outcome',
        formatFn: (pitch: IMlbPitchExt) => {
          return pitch.outcome;
        },
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          const textA = pitchA.outcome ?? '';
          const textB = pitchB.outcome ?? '';
          return -dir * textA.localeCompare(textB);
        },
      },
      {
        label: 'Zone',
        key: '_zone',
        align: 'center',
        formatFn: (pitch: IMlbPitchExt) => {
          const chars = MlbStatsHelper.convertToChars(
            pitch,
            app.pitch_upload_options.priority,
            false
          ).chars;

          if (!chars?.plate) {
            return '';
          }

          const summary = PlateHelper.getPlateSummary(chars.plate);

          return (
            <img
              alt="strike zone icon"
              className="StrikeZoneIcon"
              width={24}
              height={24}
              src={appearanceImgPath(`plate/${summary.grid}.svg`)}
            />
          );
        },
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          const charsA = MlbStatsHelper.convertToChars(
            pitchA,
            app.pitch_upload_options.priority,
            false
          ).chars;

          const charsB = MlbStatsHelper.convertToChars(
            pitchB,
            app.pitch_upload_options.priority,
            false
          ).chars;

          const sortA = charsA?.plate
            ? PlateHelper.getPlateSummary(charsA.plate).sort
            : 0;

          const sortB = charsB?.plate
            ? PlateHelper.getPlateSummary(charsB.plate).sort
            : 0;

          return -dir * (sortA < sortB ? -1 : 1);
        },
      },
      {
        label: 'common.speed',
        key: 'speed',
        subLabel: 'mph',
        align: 'right',
        formatFn: (pitch: IMlbPitchExt) => {
          const v = pitch.lastMeasuredData?.velocity;

          return MlbStatsHelper.validVelocity(v)
            ? BallHelper.getSpeed({
                vx: v?.x ?? 0,
                vy: v?.y ?? 0,
                vz: v?.z ?? 0,
              }).toFixed(1)
            : ERROR_ICON;
        },
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          const veloA = pitchA.lastMeasuredData?.velocity;
          const va = MlbStatsHelper.validVelocity(veloA)
            ? BallHelper.getSpeed({
                vx: veloA?.x ?? 0,
                vy: veloA?.y ?? 0,
                vz: veloA?.z ?? 0,
              })
            : 0;

          const veloB = pitchB.lastMeasuredData?.velocity;
          const vb = MlbStatsHelper.validVelocity(veloB)
            ? BallHelper.getSpeed({
                vx: veloB?.x ?? 0,
                vy: veloB?.y ?? 0,
                vz: veloB?.z ?? 0,
              })
            : 0;

          return dir * (va < vb ? 1 : -1);
        },
      },
      {
        label: 'common.spin',
        key: 'spin',
        subLabel: 'rpm',
        align: 'right',
        formatFn: (pitch: IMlbPitchExt) => {
          const sv = pitch.releaseData?.spinVector;

          return MlbStatsHelper.validSpinVector(sv)
            ? BallHelper.getNetSpin({
                wx: sv?.x ?? 0,
                wy: sv?.y ?? 0,
                wz: sv?.z ?? 0,
              }).toFixed(0)
            : ERROR_ICON;
        },
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          const vectorA = pitchA.releaseData?.spinVector;
          const va = MlbStatsHelper.validSpinVector(vectorA)
            ? BallHelper.getNetSpin({
                wx: vectorA?.x ?? 0,
                wy: vectorA?.y ?? 0,
                wz: vectorA?.z ?? 0,
              })
            : 0;

          const vectorB = pitchB.releaseData?.spinVector;
          const vb = MlbStatsHelper.validSpinVector(vectorB)
            ? BallHelper.getNetSpin({
                wx: vectorB?.x ?? 0,
                wy: vectorB?.y ?? 0,
                wz: vectorB?.z ?? 0,
              })
            : 0;

          return dir * (va < vb ? 1 : -1);
        },
      },
      {
        label: 'common.v-break',
        key: 'breaks_zInches',
        subLabel: 'in',
        align: 'right',
        formatFn: (pitch: IMlbPitchExt) => {
          const traj = pitch.trajectoryData;
          return MlbStatsHelper.validTrajectory(traj)
            ? ((traj?.verticalBreakInduced ?? 0) * FT_TO_INCHES).toFixed(1)
            : ERROR_ICON;
        },
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          const va = pitchA.trajectoryData?.verticalBreakInduced ?? 0;
          const vb = pitchB.trajectoryData?.verticalBreakInduced ?? 0;
          return dir * (va < vb ? 1 : -1);
        },
      },
      {
        label: 'common.h-break',
        key: 'breaks_xInches',
        subLabel: 'in',
        align: 'right',
        tooltipFn: () => PitchDesignHelper.HB_TOOLTIP_TEXT,
        formatFn: (pitch: IMlbPitchExt) => {
          const traj = pitch.trajectoryData;
          return MlbStatsHelper.validTrajectory(traj)
            ? ((traj?.horizontalBreak ?? 0) * FT_TO_INCHES).toFixed(1)
            : ERROR_ICON;
        },
        sortRowsFn: (
          pitchA: IMlbPitchExt,
          pitchB: IMlbPitchExt,
          dir: number
        ) => {
          const va = pitchA.trajectoryData?.horizontalBreak ?? 0;
          const vb = pitchB.trajectoryData?.horizontalBreak ?? 0;
          return dir * (va < vb ? 1 : -1);
        },
      },
    ];

    return output;
  }, [rowActions]);

  /** for active row selection functionality */
  const afterChangeSelected = useCallback(
    async (model: IMlbPitchExt | undefined) => {
      if (!model) {
        setAimedPitch(undefined);
        return;
      }

      if (aimedPitch?.mlb_guid === model.guid) {
        // it's already selected, don't re-trigger everything
        return;
      }

      const built = (
        await buildPitches({
          priority: getSupportedPriority(app.pitch_upload_options.priority),
          pitches: [model],
        })
      )[0];

      if (!built) {
        return;
      }

      // if a video was selected, keep using that video
      built.video_id = aimedPitch?.video_id;

      await setAimedPitch(built, {
        resetPlate: ResetPlateMode.PitchTraj,
      });
    },
    [aimedPitch, machine, buildPitches, getSupportedPriority]
  );

  const checkedMenuActions = useCallback(
    (checked: IMlbPitchExt[]): IMenuAction[] => {
      const output: IMenuAction[] = [
        {
          prefixIcon: <RowsAddIcon />,
          label: 'pd.add-all-selected-to-pitch-list',
          invisible: restricted,
          onClick: () =>
            openAddDialog({
              guids: checked,
              pitcherPk: props.mode === 'pitchers' ? props.playerPk : undefined,
            }),
        },
        {
          prefixIcon: <AddAveragesIcon />,
          label: 'pd.add-averages-to-pitch-list',
          invisible: restricted,
          onClick: async () => {
            const averages = MlbStatsHelper.averagePitches(checked);
            if (!averages || averages.length === 0) {
              NotifyHelper.warning({
                message_md: `There was a problem calculating average pitches. Please check your selections and try again.`,
              });
              return;
            }

            openAddDialog({
              guids: averages,
              pitcherPk: props.mode === 'pitchers' ? props.playerPk : undefined,
              isAverage: true,
            });
          },
        },
      ];

      return output;
    },
    [machine, restricted, aimedPitch, buildPitches, getSupportedPriority]
  );

  const data = useMemo(() => {
    // number comparison is faster for searching, but search inputs use strings
    const pitcherPks = (filterPitches.pitcherPks ?? []).map(parseInt);
    const hitterPks = (filterPitches.hitterPks ?? []).map(parseInt);

    return props.pitches
      .filter((p) => !filterPitches.gamePk || filterPitches.gamePk === p.gamePk)
      .filter(
        (p) => pitcherPks.length === 0 || pitcherPks.includes(p.pitcherPk)
      )
      .filter((p) => hitterPks.length === 0 || hitterPks.includes(p.batterPk))
      .filter(
        (p) =>
          filterPitches.isHome === undefined ||
          // home team always pitches at the top, and hits at the bottom of innings
          (filterPitches.isHome === 'home') === p.count.isTopInning
      )
      .filter(
        (p) =>
          !filterPitches.pitchType ||
          filterPitches.pitchType === lookupPitchType(p.type)
      )
      .filter(
        (p) =>
          !filterPitches.pitchHand || filterPitches.pitchHand === p.pitchHand
      )
      .filter(
        (p) => !filterPitches.batSide || filterPitches.batSide === p.batSide
      )
      .filter(
        (p) =>
          !filterPitches.outcomeType || filterPitches.outcomeType === p.outcome
      )
      .filter(
        (p) => !filterPitches.inning || filterPitches.inning === p.count.inning
      );
  }, [filterPitches]);

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: data.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const sort: ITableSortable = {
    enableSort: true,
    defaultSort: {
      key: '_inning',
      dir: 1,
    },
  };

  const select: ITableSelectable = {
    enableSelect: true,
    afterChangeSelected: afterChangeSelected,
  };

  const isFiltering = isObjectNonEmpty(filterPitches);

  const checkable: ITableCheckable = {
    checkboxColumnIndex: 0,
    checkedActions: checkedMenuActions,
  };

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <CheckedProvider data={data}>
        <CommonTable
          id={COMPONENT_NAME}
          loading={loading}
          toolbarContent={
            <GameDataPitchesFilters
              mode={props.mode}
              playerPk={props.playerPk}
              pitches={props.pitches}
            />
          }
          displayColumns={columns}
          displayData={data}
          noDataHeader="common.no-pitches-to-display"
          noDataBody={
            isFiltering ? 'common.no-matches-msg' : 'common.browse-welcome-msg'
          }
          enableListener={globalCx.dialogs.length === 0}
          {...pagination}
          {...checkable}
          {...select}
          {...sort}
          vFlex
        />
      </CheckedProvider>

      {dialogData && aimedPitch && (
        <PitchDataDialog
          key={dialogData}
          identifier={`${COMPONENT_NAME}ViewDataDialog`}
          cookiesCx={cookiesCx}
          authCx={authCx}
          machine={machine}
          pitch={aimedPitch}
          onClose={() => setDialogData(undefined)}
        />
      )}

      {dialogBroadcast && selectedGuid && (
        <ViewBroadcastDialog
          key={dialogBroadcast}
          identifier={`${COMPONENT_NAME}ViewBroadcastDialog`}
          gamePk={selectedGuid.gamePk}
          guid={selectedGuid.guid}
          onClose={() => setDialogBroadcast(undefined)}
        />
      )}

      {dialogVideo && selectedGuid && (
        <GenerateVideoDialog
          key={dialogVideo}
          identifier={`${COMPONENT_NAME}GenerateVideoDialog`}
          videosCx={videosCx}
          guid={selectedGuid}
          onClose={() => setDialogVideo(undefined)}
        />
      )}
    </ErrorBoundary>
  );
};
