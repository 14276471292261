import { ErrorBoundary } from 'components/common/error-boundary';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { CommonTableHoC } from 'components/common/table';
import { AnalyticsHeader } from 'components/sections/analytics/header';
import { HittingReportsToolbar } from 'components/sections/analytics/hitting-reports/toolbar';
import useAnalyticsStore from 'components/sections/analytics/store/use-analytics-store';
import { CookiesContext } from 'contexts/cookies.context';
import { SectionsContext } from 'contexts/sections.context';
import { lightFormat } from 'date-fns';
import format from 'date-fns-tz/format';
import parseISO from 'date-fns/parseISO';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { TABLES } from 'enums/tables';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { round } from 'lib_ts/classes/math.utilities';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { ISessionHitSummary } from 'lib_ts/interfaces/i-session-hit';
import { useContext, useMemo } from 'react';

const IDENTIFIER = TableIdentifier.HittingReports;
const PAGE_SIZES = TABLES.PAGE_SIZES.MD;

const roundToOne = (v?: number) => (v?.toFixed(1) ? round(v, 1) : null);

const generateHittingReportsColumns = (): ITableColumn[] => [
  {
    label: 'common.batter-name',
    key: 'name',
    formatFn: ({ hitterName }: ISessionHitSummary) => hitterName ?? '-',
    sortRowsFn: (a: ISessionHitSummary, b: ISessionHitSummary, dir) =>
      a.hitterName.localeCompare(b.hitterName) * dir,
  },
  {
    label: 'common.shots',
    key: 'shots',
    align: 'right',
    formatFn: ({ shots }: ISessionHitSummary) => shots?.toFixed(0) ?? '-',
    sortRowsFn: (a: ISessionHitSummary, b: ISessionHitSummary, dir) =>
      a.shots < b.shots ? dir : -dir,
  },
  {
    label: 'common.average-ev',
    subLabel: 'mph',
    key: 'exitMPH',
    align: 'right',
    formatFn: ({ exitMPH }: ISessionHitSummary) => roundToOne(exitMPH) ?? '-',
    sortRowsFn: (a: ISessionHitSummary, b: ISessionHitSummary, dir) =>
      a.exitMPH < b.exitMPH ? dir : -dir,
  },
  {
    label: 'common.average-la',
    subLabel: 'deg',
    key: 'vLaunchDEG',
    align: 'right',
    formatFn: ({ vLaunchDEG }: ISessionHitSummary) =>
      roundToOne(vLaunchDEG) ?? '-',
    sortRowsFn: (a: ISessionHitSummary, b: ISessionHitSummary, dir) =>
      a.vLaunchDEG < b.vLaunchDEG ? dir : -dir,
  },
  {
    label: 'common.average-dist',
    subLabel: 'ft',
    key: 'distanceFT',
    align: 'right',
    formatFn: ({ distanceFT }: ISessionHitSummary) =>
      roundToOne(distanceFT) ?? '-',
    sortRowsFn: (a: ISessionHitSummary, b: ISessionHitSummary, dir) =>
      a.distanceFT < b.distanceFT ? dir : -dir,
  },
  {
    label: 'common.created',
    key: 'startDate',
    formatFn: ({ startDate }: ISessionHitSummary) =>
      format(parseISO(startDate), LOCAL_DATETIME_FORMAT, {
        timeZone: LOCAL_TIMEZONE,
      }),
  },
];

export const HittingReports = () => {
  const { tryChangeSection } = useContext(SectionsContext);
  const { getPageSize, setPageSize } = useContext(CookiesContext);

  // machineSessions fetched by Analytics parent component
  const { machineSessions, batterFilter, startDateFilter, loading } =
    useAnalyticsStore();

  const filteredSessions = useMemo(() => {
    return machineSessions
      .filter(
        (session) =>
          batterFilter.length === 0 || batterFilter.includes(session.hitterID)
      )
      .filter(
        (session) =>
          startDateFilter.length === 0 ||
          startDateFilter.includes(
            lightFormat(parseISO(session.startDate), 'yyyy-MM-dd')
          )
      );
  }, [machineSessions, batterFilter, startDateFilter]);

  const columns = useMemo(() => generateHittingReportsColumns(), []);

  const pagination: ITablePageable = {
    identifier: IDENTIFIER,
    total: filteredSessions.length,
    enablePagination: true,
    pageSizes: PAGE_SIZES,
  };

  const select: ITableSelectable = {
    enableSelect: true,
    afterChangeSelected: (m: ISessionHitSummary | undefined) => {
      if (!m) {
        return;
      }

      tryChangeSection({
        trigger: 'Open hitter session from hitting report',
        section: SectionName.Analytics,
        subsection: SubSectionName.HitterSession,
        fragments: [m.session, m.hitterID],
      });
    },
  };

  const sort: ITableSortable = {
    enableSort: true,
  };

  return (
    <ErrorBoundary componentName="HittingReports">
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={<AnalyticsHeader />}
        table={
          <CommonTableHoC
            id={IDENTIFIER}
            displayColumns={columns}
            displayData={filteredSessions}
            loading={loading}
            toolbarContent={<HittingReportsToolbar />}
            {...pagination}
            {...select}
            {...sort}
            vFlex
          />
        }
      />
    </ErrorBoundary>
  );
};
