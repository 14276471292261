import { ResetIcon } from '@radix-ui/react-icons';
import { Box, Flex, Grid } from '@radix-ui/themes';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSearchInput } from 'components/common/form/search';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTableButton } from 'components/common/table/button';
import { GameDataContext } from 'contexts/game-data.context';
import { CheckedContext } from 'contexts/layout/checked.context';
import { GameDataTab } from 'enums/game-data.enums';
import { BatSide, PitchHand } from 'interfaces/i-mlb-browse';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { HitterSide } from 'lib_ts/enums/hitters.enums';
import { MLB_OUTCOME_CODES } from 'lib_ts/enums/mlb-stats-api/guid-metadata-types.enum';
import {
  PITCH_TYPE_OPTIONS,
  PitcherHand,
  PitchType,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import { useContext, useEffect, useMemo, useState } from 'react';

const COMPONENT_NAME = 'GameDataPitchesFilters';

export const GameDataPitchesFilters = (props: {
  mode: GameDataTab;
  pitches: IMlbPitchExt[];
  playerPk?: number;
}) => {
  const checkedCx = useContext(CheckedContext);
  const {
    filterPitches,
    seasonGames,
    seasonPlayers,
    seasonTeams,
    mergeFilterPitches,
  } = useContext(GameDataContext);

  // reset filters on mount
  useEffect(
    () =>
      mergeFilterPitches({
        gamePk: undefined,

        pitcherPks: undefined,
        pitchHand: undefined,

        hitterPks: undefined,
        batSide: undefined,

        pitchType: undefined,
        inning: undefined,
        outcomeType: undefined,
      }),
    []
  );

  const gameOptions: IOption[] = useMemo(() => {
    return seasonGames
      .filter((g) => {
        if (!props.playerPk) {
          return false;
        }

        switch (props.mode) {
          case 'hitters': {
            return g.batterPks.includes(props.playerPk);
          }

          case 'pitchers': {
            return g.pitcherPks.includes(props.playerPk);
          }

          default: {
            return false;
          }
        }
      })
      .map((g) => {
        const homeTeam = seasonTeams.find((m) => m.teamPk === g.home.teamPk);
        const awayTeam = seasonTeams.find((m) => m.teamPk === g.away.teamPk);

        const o: IOption = {
          label: `${g.officialDate}: ${awayTeam?.code ?? g.away.name} @ ${
            homeTeam?.code ?? g.home.name
          }`,
          value: g.gamePk.toString(),
          group: g.officialDate.substring(0, 7),
        };
        return o;
      });
  }, [seasonGames, seasonTeams, props.mode, props.playerPk]);

  const outcomeOptions: IOption[] = useMemo(() => {
    const unique = ArrayHelper.unique(
      props.pitches.map((m) => m.outcome as string)
    );
    return MLB_OUTCOME_CODES.filter((m) => unique.includes(m.value));
  }, [props.pitches]);

  const inningOptions: IOption[] = useMemo(() => {
    const unique = ArrayHelper.unique(props.pitches.map((m) => m.count.inning));
    return unique.map((n) => {
      const o: IOption = {
        label: n.toString(),
        value: n.toString(),
      };
      return o;
    });
  }, [props.pitches]);

  const typeOptions: IOption[] = useMemo(() => {
    const unique = ArrayHelper.unique(props.pitches.map((m) => m.type));
    return PITCH_TYPE_OPTIONS.filter((m) => unique.includes(m.value));
  }, [props.pitches]);

  const hitterOptions = useMemo(() => {
    if (props.mode === 'hitters') {
      // irrelevant
      return [];
    }

    const uniquePk = ArrayHelper.unique(props.pitches.map((m) => m.batterPk));

    return seasonPlayers
      .filter((m) => uniquePk.includes(m.playerPk))
      .map((m) => {
        const o: IOption = {
          label: m.name,
          aliases: [
            m.teamCode ?? '',
            m.teamName ?? '',
            m.teamShortName ?? '',
          ].filter((s) => s),
          value: m.playerPk.toString(),
        };
        return o;
      });
  }, [props.pitches, seasonPlayers]);

  const pitcherOptions = useMemo(() => {
    if (props.mode === 'pitchers') {
      // irrelevant
      return [];
    }

    const uniquePk = ArrayHelper.unique(props.pitches.map((m) => m.pitcherPk));

    return seasonPlayers
      .filter((m) => uniquePk.includes(m.playerPk))
      .map((m) => {
        const o: IOption = {
          label: m.name,
          aliases: [
            m.teamCode ?? '',
            m.teamName ?? '',
            m.teamShortName ?? '',
          ].filter((s) => s),
          value: m.playerPk.toString(),
        };
        return o;
      });
  }, [props.pitches, seasonPlayers]);

  const columns = useMemo(() => {
    switch (props.mode) {
      case 'games': {
        return '5';
      }

      case 'pitchers': {
        return '4';
      }

      case 'hitters': {
        return '4';
      }

      default: {
        return '9';
      }
    }
  }, [props.mode]);

  const [resetAllKey, setResetAllKey] = useState(Date.now());

  return (
    <ErrorBoundary componentName={COMPONENT_NAME}>
      <Flex gap={RADIX.FLEX.GAP.SM}>
        <Grid flexGrow="1" gap={RADIX.FLEX.GAP.SM} columns={columns}>
          {(props.mode === 'hitters' || props.mode === 'pitchers') && (
            <CommonSearchInput
              key={`gamePk-${resetAllKey}`}
              id="mlb-stats-gamePk"
              name="gamePk"
              placeholder="Game"
              options={gameOptions}
              values={
                filterPitches.gamePk ? [filterPitches.gamePk.toString()] : []
              }
              onChange={(v) => {
                checkedCx.resetChecked();
                mergeFilterPitches({
                  gamePk: v ? parseInt(v[0]) : undefined,
                });
              }}
              optional
              skipSort
            />
          )}

          {props.mode === 'hitters' && (
            <CommonSelectInput
              key={`hand-${resetAllKey}`}
              id="mlb-stats-pitcher-hand"
              name="pitchHand"
              placeholder="Pitcher Hand"
              options={[
                { label: PitcherHand.RHP, value: 'R' },
                { label: PitcherHand.LHP, value: 'L' },
              ]}
              value={filterPitches.pitchHand}
              onChange={(v) => {
                checkedCx.resetChecked();
                mergeFilterPitches({ pitchHand: v as PitchHand });
              }}
              optional
            />
          )}

          {props.mode !== 'pitchers' && (
            <CommonSearchInput
              key={`pitchers-${resetAllKey}`}
              id="mlb-stats-pitchers"
              placeholder="common.pitcher"
              options={pitcherOptions}
              values={filterPitches.pitcherPks ?? []}
              onChange={(v) => {
                checkedCx.resetChecked();
                mergeFilterPitches({ pitcherPks: v });
              }}
              optional
            />
          )}

          {props.mode === 'pitchers' && (
            <CommonSelectInput
              key={`side-${resetAllKey}`}
              id="mlb-stats-batter-side"
              name="batSide"
              placeholder="hitters.hitter-side"
              options={[
                { label: HitterSide.RHH, value: 'R' },
                { label: HitterSide.LHH, value: 'L' },
              ]}
              value={filterPitches.batSide}
              onChange={(v) => {
                checkedCx.resetChecked();
                mergeFilterPitches({ batSide: v as BatSide });
              }}
              optional
            />
          )}

          {props.mode !== 'hitters' && (
            <CommonSearchInput
              key={`hitters-${resetAllKey}`}
              id="mlb-stats-hitters"
              placeholder="common.hitter"
              options={hitterOptions}
              values={filterPitches.hitterPks ?? []}
              onChange={(v) => {
                checkedCx.resetChecked();
                mergeFilterPitches({ hitterPks: v });
              }}
              optional
            />
          )}

          <CommonSelectInput
            key={`type-${resetAllKey}`}
            id="mlb-stats-pitch-type"
            name="pitchType"
            placeholder="common.pitch-type"
            options={typeOptions}
            value={filterPitches.pitchType}
            onChange={(v) => {
              checkedCx.resetChecked();
              mergeFilterPitches({ pitchType: v as PitchType });
            }}
            optional
          />

          {props.mode === 'games' && (
            <CommonSelectInput
              key={`inning-${resetAllKey}`}
              id="mlb-stats-inning"
              name="inning"
              placeholder="common.inning"
              options={inningOptions}
              value={filterPitches.inning?.toString()}
              onNumericChange={(v) => {
                checkedCx.resetChecked();
                mergeFilterPitches({ inning: v });
              }}
              optional
            />
          )}

          {props.mode === 'games' && (
            <CommonSearchInput
              key={`outcome-${resetAllKey}`}
              id="mlb-stats-outcome"
              name="outcomeType"
              placeholder="common.outcome"
              options={outcomeOptions}
              values={
                filterPitches.outcomeType ? [filterPitches.outcomeType] : []
              }
              onChange={(v) => {
                checkedCx.resetChecked();
                mergeFilterPitches({ outcomeType: v[0] });
              }}
              optional
            />
          )}
        </Grid>

        <Box>
          <CommonTableButton
            icon={<ResetIcon />}
            label="common.reset"
            className="btn-block"
            variant="soft"
            color={RADIX.COLOR.NEUTRAL}
            onClick={() => {
              checkedCx.resetChecked();

              // clear the filter values
              mergeFilterPitches({
                isHome: undefined,
                pitchType: undefined,
                inning: undefined,
                outcomeType: undefined,
                batSide: undefined,
                pitchHand: undefined,
                gamePk: undefined,
                hitterPks: undefined,
                pitcherPks: undefined,
              });

              // clear the select inputs
              setResetAllKey(Date.now());
            }}
          />
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};
